import {
    BlankSlate,
    Description,
    PrimaryActionButton
} from "@cjdev/visual-stack/lib/components/BlankSlate";

import "../ErrorMessage/ErrorMessage.css"
import cjHeaderLogo from "./cj_header_logo_black.svg"

export const UnblockSuccessMessage = (props) =>
    <BlankSlate
        title={"Account Unblocked"}
        headerGraphic={<img
            src={cjHeaderLogo}
            alt={"CJ Affiliate"}
            className={"headerGraphic"}
        />}
        >

        <Description>
            Your account has been unblocked successfully.
        </Description>

        <PrimaryActionButton
            label={"Return to login"}
            handler={() => props.history.push("https://members.cj.com/member/login")}
        />
    </BlankSlate>